import * as React from 'react'

import { isAfter, isBefore } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex, useDisclosure } from '@chakra-ui/react'

import {
  DateFormat,
  TextWithTooltip,
  formatTimestamp,
  getShortenUUID,
  useToast,
} from '@enechain/common-ui'
import {
  AccordionIconButton,
  FlexTableCell,
  FlexTableRow,
  SecondaryButton,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { SleeveType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'
import { CreateEmptyResponse } from '@enechain/etender-proto/proto/etender/order/v1/service_pb'
import { Attachment } from '@enechain/etender-proto/proto/etender/tender/v1/model_pb'

import AttachmentFileItem from '~/components/AttachmentFileItem'
import CreateOrderModal from '~/pages/tender/components/TenderTable/CreateOrderModal'
import { TenderColumnKey } from '~/pages/tender/components/TenderTable/TenderColumnStyles'
import { useCreateEmptyOrder } from '~/pages/tender/hooks/useCreateEmptyOrder'
import { useTenderById } from '~/pages/tender/hooks/useTenderList'
import { routePaths } from '~/routes/routePaths'

type TenderTableRowData = {
  id: string
  shortenId: string
  name: string
  openAt: string
  ultimateBuyerCloseAt: string
  closeAt: string
  bidEnabled: boolean
  sleeversEnabled: boolean
  withSleeverAvailable: boolean
  awardAnnounceAt: string
  ownerOrganizationName: string | undefined
  attachments: Attachment[]
}

const useTenderTableRowData = (
  tenderId: string,
): TenderTableRowData | undefined => {
  const { data } = useTenderById<TenderTableRowData>(tenderId, (response) => {
    const tender = response.tender

    const shortenId = getShortenUUID(tender?.id ?? '')
    const ownerOrganization =
      tender?.ownerOrganizationId != null
        ? response.dataSet?.organizations[tender.ownerOrganizationId]
        : undefined

    const isBidEnabled = (): boolean => {
      const currentDate = new Date()
      if (
        tender?.schedule?.openAt != null &&
        tender?.schedule?.closeAt != null
      ) {
        return (
          (tender?.bidEnabled ?? false) &&
          isAfter(currentDate, tender?.schedule?.openAt?.toDate()) &&
          isBefore(currentDate, tender?.schedule?.closeAt?.toDate())
        )
      }
      return false
    }
    const bidEnabled = isBidEnabled()
    // tenderがスリーバーを利用できるかどうか
    const sleeversEnabled = tender?.sleeversEnabled ?? false
    // 応札者がスリーバーを利用可能かどうか
    const isWithSleeverAvailable = (): boolean => {
      const currentDate = new Date()
      // UB応札締切が未設定ならclosedAtを用いる
      const ultimateBuyerCloseAt =
        tender?.schedule?.ultimateBuyerCloseAt != null
          ? tender?.schedule?.ultimateBuyerCloseAt
          : tender?.schedule?.closeAt
      if (tender?.schedule?.openAt != null && ultimateBuyerCloseAt != null) {
        return (
          (tender?.bidEnabled ?? false) &&
          isAfter(currentDate, tender?.schedule?.openAt?.toDate()) &&
          isBefore(currentDate, ultimateBuyerCloseAt.toDate())
        )
      }
      return false
    }
    const withSleeverAvailable = isWithSleeverAvailable()

    return {
      id: tender?.id ?? '',
      shortenId,
      name: tender?.properties?.name ?? '',
      openAt: formatTimestamp(
        tender?.schedule?.openAt,
        DateFormat.DATE_TIME_MINUTE_SLASH,
      ),
      ultimateBuyerCloseAt: formatTimestamp(
        tender?.schedule?.ultimateBuyerCloseAt,
        DateFormat.DATE_TIME_MINUTE_SLASH,
      ),
      closeAt: formatTimestamp(
        tender?.schedule?.closeAt,
        DateFormat.DATE_TIME_MINUTE_SLASH,
      ),
      bidEnabled,
      sleeversEnabled,
      withSleeverAvailable,
      awardAnnounceAt: formatTimestamp(
        tender?.schedule?.awardAnnounceAt,
        DateFormat.DATE_TIME_MINUTE_SLASH,
      ),
      ownerOrganizationName: ownerOrganization?.name,
      attachments: tender?.attachments ?? [],
    }
  })
  return data
}

type Props = {
  tenderId: string
}

const TenderTableRow: React.FC<Props> = ({ tenderId }) => {
  const { t } = useTranslation('trader', { keyPrefix: 'tender.list' })
  const { t: commonT } = useTranslation('common')

  const disclosure = useDisclosure()
  const createEmptyMutation = useCreateEmptyOrder()
  const navigate = useNavigate()
  const { showToast } = useToast({
    toastId: 'pages/tender/components/CreateOrderModal',
  })

  const tenderTableRowData = useTenderTableRowData(tenderId)
  if (tenderTableRowData == null) {
    return null
  }

  const onClickOrder = (): void => {
    // スリーバーを利用しないTenderの場合は、直接応札のみなのでそのまま処理に進む ※Modalを経由しない
    if (!tenderTableRowData.sleeversEnabled) {
      onClickCreateDirectEmptyOrder()
      return
    }

    // tenderの応札UIの表示
    disclosure.onOpen()
  }

  const onClickCreateDirectEmptyOrder = (): void => {
    createEmptyMutation.mutate(
      {
        tenderId,
        sleeveType: SleeveType.NONE, // direct
      },
      {
        onSuccess: ({ editingOrder }: CreateEmptyResponse): void => {
          if (editingOrder == null) {
            return
          }

          navigate(
            routePaths.order({
              params: {
                tenderId: editingOrder.tenderId,
                orderId: editingOrder.id,
              },
            }),
          )
        },
        onError: (): void => {
          showToast({
            status: 'error',
            toastMessage: t('create_order_modal.failed_to_create_order'),
          })
        },
      },
    )
  }

  return (
    <>
      <FlexTableRow>
        <FlexTableCell<TenderColumnKey> columnKey="accordion">
          <Flex marginStart={Spacing['-2']}>
            <AccordionIconButton />
          </Flex>
        </FlexTableCell>
        <FlexTableCell<TenderColumnKey>
          columnKey="tenderId"
          contents={{
            text: tenderTableRowData.shortenId,
            fullText: tenderTableRowData.id,
          }}
        />
        <FlexTableCell<TenderColumnKey>
          columnKey="ownerOrganization"
          contents={{
            text:
              tenderTableRowData.ownerOrganizationName ??
              commonT('label.private'),
            textMaxLines: 4,
          }}
        />
        <FlexTableCell<TenderColumnKey>
          columnKey="name"
          contents={{ text: tenderTableRowData.name, textMaxLines: 4 }}
        />
        <FlexTableCell<TenderColumnKey>
          columnKey="openAt"
          contents={{ text: tenderTableRowData.openAt, textMaxLines: 4 }}
        />
        <FlexTableCell<TenderColumnKey>
          columnKey="closeAt"
          contents={{
            text: tenderTableRowData.closeAt,
            textMaxLines: 4,
          }}
        />
        <FlexTableCell<TenderColumnKey>
          columnKey="ultimateBuyerCloseAt"
          contents={{
            text:
              tenderTableRowData.ultimateBuyerCloseAt === ''
                ? commonT('label.no_setting')
                : tenderTableRowData.ultimateBuyerCloseAt,
            textMaxLines: 4,
          }}
        />
        <FlexTableCell<TenderColumnKey>
          columnKey="awardAnnounceAt"
          contents={{
            text: tenderTableRowData.awardAnnounceAt,
            textMaxLines: 4,
          }}
        />
        <FlexTableCell<TenderColumnKey> columnKey="attachments">
          <Flex flexDirection="column" gap={1} width="100%">
            {tenderTableRowData.attachments.length === 0 ? (
              <TextWithTooltip
                text={commonT('label.no_setting')}
                typography={Typography.textMd}
              />
            ) : (
              tenderTableRowData.attachments.map((attachment) => {
                return (
                  <AttachmentFileItem
                    key={attachment.id}
                    attachment={attachment}
                    tenderId={tenderTableRowData.id}
                  />
                )
              })
            )}
          </Flex>
        </FlexTableCell>
        <FlexTableCell<TenderColumnKey> columnKey="action">
          <SecondaryButton
            height="32px"
            isDisabled={!tenderTableRowData.bidEnabled}
            onClick={onClickOrder}
          >
            {t('action.bid')}
          </SecondaryButton>
        </FlexTableCell>
      </FlexTableRow>
      <CreateOrderModal
        disclosure={disclosure}
        tenderId={tenderTableRowData.id}
        withSleeverAvailable={tenderTableRowData.withSleeverAvailable}
      />
    </>
  )
}

export default React.memo(TenderTableRow)
