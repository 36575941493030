export type PathFunction<
  TParams extends Record<string, string> | undefined = undefined,
  TQuery extends Record<string, string> | undefined = undefined,
> = (
  args?: TParams extends undefined
    ? TQuery extends undefined
      ? undefined
      : { query?: TQuery }
    : TQuery extends undefined
      ? { params: TParams }
      : { params: TParams; query?: TQuery },
) => string

export function generatePathFromQuery(
  query: Record<string, string> | undefined,
): string {
  if (query == null || Object.keys(query).length === 0) {
    return ''
  }

  const queryParameters = new URLSearchParams()
  for (const [key, value] of Object.entries(query)) {
    if (value.length === 0) {
      continue
    }
    queryParameters.set(key, value)
  }

  let size = 0
  // NOTE: URLSearchParams.size is not supported in some Edge versions
  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/size#browser_compatibility
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const _ of queryParameters) {
    size++
  }

  return size > 0 ? `?${queryParameters.toString()}` : ''
}
